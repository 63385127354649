import React, { Fragment } from "react";
import { Icon, Popconfirm, Button, Rate, Tooltip, Alert, Popover, Badge } from "antd";
import Switch from "../uielements/switch";
import { Link } from "react-router-dom";
import ImageCellView from "./imageCell";
import DeleteCell from "./deleteCell";
import EditableCell from "./editableCell";
import FilterDropdown from "./filterDropdown";
import config from "../../settings";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Notification from "../../components/notification";
import Copy from "./Copy";
import { serverBaseUrl, toTitleCase } from "../../helpers/utility";
import "./style.css";
import Drop from "../../image/drop.svg";


const delIconStyle = {
  fontSize: 17,
  marginRight: 10,
  cursor: "pointer",
  color: "#FF0000",
};

const iconStyle = {
  fontSize: 17,
  marginRight: 10,
  cursor: "pointer",
  color: "#1d73b3",
};
const delButtonStyle = {
  maxHeight: 35,
  maxWidth: 35,
  cursor: "pointer",
  color: "#FF0000",
};

const editButtonStyle = {
  maxHeight: 35,
  maxWidth: 35,
  cursor: "pointer",
  color: "#1d73b3",
};
const publishButtonStyle = {
  maxHeight: 60,
  maxWidth: 100,
  cursor: "pointer",
  color: "#1d73b3",
};

const publishedButtonStyle = {
  maxHeight: 60,
  maxWidth: 100,
  // cursor: "pointer",
  color: "#1d73b3",
};

const viewIconStyle = {
  fontSize: 17,
  marginRight: 10,
  cursor: "pointer",
  color: "#67b58f",
};

const removeIconStyle = {
  fontSize: 17,
  marginLeft: 10,
  cursor: "pointer",
  color: "#f34545",
};

const checkTrueIConStyle = {
  fontSize: 20,
  marginRight: 10,
  cursor: "pointer",
  color: "#67b58f",
};

const checkFalseIConStyle = {
  fontSize: 20,
  marginRight: 10,
  cursor: "pointer",
  color: "#f5222d",
};

const checkFalseIConStyleNotAtt = {
  fontSize: 20,
  marginRight: 10,
  cursor: "pointer",
  color: "rgb(125 125 125)",
}

const imageStyle = {
  height: 50,
  width: 50,
};
const confirm = (remove, object) => {
  remove(object);
};

const cancel = (e) => {
  e.stopPropagation();
  return false;
};
const stopPropagation = (e) => {
  try {
    e.stopPropagation();
  } catch (e) {}
};

const DateCell = (data) => (
  <p>{(typeof data === "string" ? data : "").toLocaleString()}</p>
);

const SwitchCell = (data, onChange) => (
  <Switch
    onChange={(val, e) => {
      stopPropagation(e);
      onChange(data, val);
    }}
    checked={data.isLocked == true}
    checkedChildren="Unlock"
    unCheckedChildren="Lock"
  />
);
const multiSwitchCellReplaceAss = (data, unAssignUserFn, assignUserFn) => (
  <Popconfirm
    title="Are you sure to change the status?"
    onConfirm={(e, val, text) => {
      stopPropagation(e);
      // onChange(data, data?.lockedStatus);
      if(data.user){
        console.log("keri")
        unAssignUserFn(data, unAssignUserFn);
      }
      else{
        console.log("keri1")
        assignUserFn(data, assignUserFn);
      }
    }}
    style={{ padding: "10px" }}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
    <Switch
      onChange={(val, e) => {
        stopPropagation(e);
      }}
      className={
        data?.userStatus === "on_pending" ||
        data?.userStatus === "off_pending"
          ? "switchButtonPending"
          : ""
      }
      checked={!["off", "on_pending"].includes(data?.userStatus)}
      unCheckedChildren={
        data?.userStatus === "off"
          ? "Unassigned"
          : data?.userStatus === "on_pending"
            ? "Assigning..."
            : ""
      }
      checkedChildren={
        data?.userStatus === "on"
          ? "Assigned"
          : data?.userStatus === "off_pending"
            ? "Unassigning..."
            : ""
      }
    />
  </Popconfirm>
);
const multiSwitchCellReplace = (data, onChange) => (
  <Popconfirm
    title="Are you sure to change the status?"
    onConfirm={(e, val, text) => {
      stopPropagation(e);
      onChange(data, data?.replace);
    }}
    style={{ padding: "10px" }}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
    <Switch
      onChange={(val, e) => {
        stopPropagation(e);
      }}
      // className={
      //   data?.lockedStatus === "on_pending" ||
      //   data?.lockedStatus === "off_pending"
      //     ? "switchButtonPending"
      //     : ""
      // }
      checked={data?.replace}
      unCheckedChildren="Replace"
      checkedChildren="Replaced"
      // checkedChildren={
      //   data?.lockedStatus === "off"
      //     ? "UNLOCKED"
      //     : data?.lockedStatus === "on_pending"
      //       ? "Locking..."
      //       : ""
      // }
      // unCheckedChildren={
      //   data?.lockedStatus === "on"
      //     ? "LOCKED"
      //     : data?.lockedStatus === "off_pending"
      //       ? "Unlocking..."
      //       : ""
      // }
    />
  </Popconfirm>
);
const multiSwitchCell = (data, onChange) => (
  <Popconfirm
    title="Are you sure to change the status?"
    onConfirm={(e, val, text) => {
      stopPropagation(e);
      onChange(data, data?.lockedStatus);
    }}
    style={{ padding: "10px" }}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
    <Switch
      onChange={(val, e) => {
        stopPropagation(e);
      }}
      className={
        data?.lockedStatus === "on_pending" ||
        data?.lockedStatus === "off_pending"
          ? "switchButtonPending"
          : ""
      }
      checked={!["off", "on_pending"].includes(data?.lockedStatus)}
      unCheckedChildren={
        data?.lockedStatus === "off"
          ? "UNLOCKED"
          : data?.lockedStatus === "on_pending"
            ? "Locking..."
            : ""
      }
      checkedChildren={
        data?.lockedStatus === "on"
          ? "LOCKED"
          : data?.lockedStatus === "off_pending"
            ? "Unlocking..."
            : ""
      }
      // checkedChildren={
      //   data?.lockedStatus === "off"
      //     ? "UNLOCKED"
      //     : data?.lockedStatus === "on_pending"
      //       ? "Locking..."
      //       : ""
      // }
      // unCheckedChildren={
      //   data?.lockedStatus === "on"
      //     ? "LOCKED"
      //     : data?.lockedStatus === "off_pending"
      //       ? "Unlocking..."
      //       : ""
      // }
    />
  </Popconfirm>
);
const customSwitchCell = (value, onChange, onText = "On", offText = "Off") => (
  <Switch
    onChange={(value, e) => {
      if (onChange) {
        e.stopPropagation();
        onChange(value);
      }
    }}
    checked={!!value}
    checkedChildren={onText}
    unCheckedChildren={offText}
  />
);

const ApproveCell = (data, onChange) => (
  <Switch
    onChange={(val, e) => {
      stopPropagation(e);
      onChange(data, val);
    }}
    checked={data.isApproved == true}
    checkedChildren="Approved"
    unCheckedChildren="Unapproved"
  />
);

const isPublicCell = (data, onChange) => (
  <Switch
    onChange={(val, e) => {
      stopPropagation(e);
      onChange(data, val);
    }}
    checked={data.isPublic == true}
    checkedChildren="Public"
    unCheckedChildren="Private"
  />
);

const isExploreCell = (data, onChange) => (
  <Switch
    onChange={(val, e) => {
      stopPropagation(e);
      onChange(data, val);
    }}
    checked={data?.canExplore == true}
    checkedChildren="On"
    unCheckedChildren="Off"
  />
);

const ButtonCell = (data, handleClick) =>
  !data.forLesson && !data.publish && data?.hasUsers ? (
    <Popconfirm
      title="Are you sure you want to publish this test?"
      onConfirm={(e) => {
        e.stopPropagation();
        handleClick(e, data);
      }}
      onCancel={(e) => {
        e.stopPropagation();
      }}
      okText="Yes"
      cancelText="No"
    >
      <Button
        onClick={(e) => {
          e.stopPropagation();
        }}
        title="Publish"
        style={{
          fontSize: 14,
          backgroundColor: "#fff",
          color: "#006FB8",
          borderRadius: 5,
        }}
      >
        Publish
      </Button>
    </Popconfirm>
  ) : (
    <Popconfirm
      disabled={data?.isPublished}
      title="Are you sure you want to publish this test?"
      onConfirm={(e) => {
        e.stopPropagation();
        handleClick(e, data);
      }}
      onCancel={(e) => {
        e.stopPropagation();
      }}
      okText="Yes"
      cancelText="No"
    >
    <Button
      style={{
        fontSize: 14,
        backgroundColor:
          data.publish || (data.forLesson && data.addedToLessonId)
            ? "#006FB8"
            : "#fff",
        color:
          data.publish || (data.forLesson && data.addedToLessonId)
            ? "#fff"
            : "#006FB8",
        borderRadius: 5,
      }}
      title={data?.isPublished ? "Published" : "Publish"}
      disabled={data?.isPublished}
      onClick={(e) => {
        e.stopPropagation()
        // handleClick(e, data)
      }}
    >
      {/* {data.forLesson && data.addedToLessonId
        ? "Linked to Lesson"
        : data.forLesson && !data.addedToLessonId
          ? "Link to Lesson"
          : data.publish
            ? "mcqStudents"
            : data?.hasUsers
              ? "Publish"
              : "Add Accession"} */}
        {data?.isPublished ? "Published" : "Publish"}
        {/* Publish */}
    </Button>
    </Popconfirm>
  );

const PinCell = (data, onChange) =>
  data.isPinned === true ? (
    <Switch
      onChange={(val, e) => {
        stopPropagation(e);
        onChange(data, val);
      }}
      checked={data.isPinned == true}
      checkedChildren="Pinned"
      unCheckedChildren="Unpinn"
    />
  ) : (
    "Unpinned"
  );

const multiPinSwitch = (data, onChange) => (
  <Popconfirm
    title="Are you sure to change the status?"
    onConfirm={(e, val, text) => {
      stopPropagation(e);
      onChange(data, data.pinnedStatus);
    }}
    style={{ padding: "10px" }}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
    <Switch
      onChange={(val, e) => {
        stopPropagation(e);
        // onChange(data, val);
      }}
      className={
        data?.pinnedStatus === "on_pending" ||
        data?.pinnedStatus === "off_pending"
          ? "switchButtonPending"
          : ""
      }
      checked={
        !(data?.pinnedStatus === "off" || data?.pinnedStatus === "on_pending")
      }
      unCheckedChildren={
        data?.pinnedStatus === "off" 
          ? "UNPINNED" : data?.pinnedStatus === "on_pending" ? "Pinning..." : ""
      }
      checkedChildren={
        data?.pinnedStatus === "on"
          ? "PINNED"
          : data?.pinnedStatus === "off_pending" ? "UnPinning..." : ""
      }
      // checkedChildren={
      //   data?.pinnedStatus === "off" || data?.pinnedStatus === "on_pending"
      //     ? "UNLOCKED"
      //     : "Unlocking..."
      // }
      // unCheckedChildren={
      //   data?.pinnedStatus === "on" || data?.pinnedStatus === "off_pending"
      //     ? "LOCKED"
      //     : "Locking..."
      // }
    />
  </Popconfirm>
);

const WifiCell = (data, onChange) => (
  <Popconfirm
    title="Are you sure to change the status?"
    onConfirm={(e, val, text) => {
      stopPropagation(e);
      onChange(data, data.isWifiOn);
    }}
    style={{ padding: "10px" }}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
    <Switch
      onChange={(val, e) => {
        stopPropagation(e);
        // onChange(data, val);
      }}
      checked={data.isWifiOn == true}
      checkedChildren="On"
      unCheckedChildren="Off"
    />
  </Popconfirm>
);

const mulitWifiSwitch = (data, onChange) => (
  <Popconfirm
    title="Are you sure to change the status?"
    onConfirm={(e, val, text) => {
      stopPropagation(e);
      onChange(data, data.isWifiOn);
    }}
    style={{ padding: "10px" }}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
    <Switch
      onChange={(val, e) => {
        stopPropagation(e);
        // onChange(data, val);
      }}
      className={
        data?.wifiStatus === "on_pending" || data?.wifiStatus === "off_pending"
          ? "switchButtonPending"
          : ""
      }
      checked={!(data?.wifiStatus === "off" || data?.wifiStatus === "on_pending")}
      unCheckedChildren={data?.wifiStatus === "off" ? "Off" : "On..."}
      checkedChildren={data?.wifiStatus === "on" ? "On" : "Off..."}
      // checkedChildren={data?.wifiStatus === "off" ? "Off" : "On..."}
      // unCheckedChildren={data?.wifiStatus === "on" ? "On" : "Off..."}
    />
  </Popconfirm>
);

const HealthCell = (data, onChange) =>
  data?.escalationLevel ? (
    <Switch
      onChange={(e) => onChange(data, e)}
      checked={data?.isClosed !== true}
      checkedChildren="Open"
      unCheckedChildren="Closed"
    />
  ) : (
    <Popover
      content={
        <Alert
          message="Please categories escalation level"
          type="warning"
          showIcon
        />
      }
      trigger="hover"
    >
      <div>
        <Switch
          checked={data?.isClosed !== true}
          checkedChildren="Open"
          unCheckedChildren="Closed"
          disabled
        />
      </div>
    </Popover>
  );

const CSDKStatusCell = (data, onChange) => (
  <Switch
    onChange={(value, e) => {
      e.stopPropagation();
      if (onChange) onChange(data, value);
    }}
    // disabled={data?.isCSDKActivated === true}
    checked={data?.isCSDKActivated == true}
    checkedChildren="Active"
    unCheckedChildren="Inactive"
  />
);
const multiCSDKSwitch = (data, onChange) => (
  <div>
    {data?.csdkStatus === "on" ? "Active" : "Inactive"}
  </div>
  // <Popconfirm
  //   title="Are you sure to change the status?"
  //   onConfirm={(e, val, text) => {
  //     stopPropagation(e);
  //     onChange(data, data.csdkStatus);
  //   }}
  //   style={{ padding: "10px" }}
  //   onCancel={cancel}
  //   okText="Yes"
  //   cancelText="No"
  // >
  //   <Switch
  //     onChange={(val, e) => {
  //       stopPropagation(e);
  //       // onChange(data, val);
  //     }}
  //     className={
  //       data?.csdkStatus === "on_pending" || data?.csdkStatus === "off_pending"
  //         ? "switchButtonPending"
  //         : ""
  //     }
  //     checked={!(data?.csdkStatus === "off" || data?.csdkStatus === "on_pending")}
  //     unCheckedChildren={data?.csdkStatus === "off" ? "Inactive" : "Active..."}
  //     checkedChildren={data?.csdkStatus === "on" ? "Active" : "Inactive..."}
  //     // checkedChildren={data?.csdkStatus === "off" ? "Inactive" : "Active..."}
  //     // unCheckedChildren={data?.csdkStatus === "on" ? "Active" : "Inactive..."}
  //   />
  // </Popconfirm>
);

const multiDeviceOwnerSwitch = (data, onChange) => (
  <div>
    {data?.deviceOwnerStatus === "on" ? "Yes" : "No"}
  </div>
  // <Popconfirm
  //   title="Are you sure to change the status?"
  //   onConfirm={(e, val, text) => {
  //     stopPropagation(e);
  //     onChange(data, data.deviceOwnerStatus);
  //   }}
  //   style={{ padding: "10px" }}
  //   onCancel={cancel}
  //   okText="Yes"
  //   cancelText="No"
  // >
  //   <Switch
  //     onChange={(val, e) => {
  //       stopPropagation(e);
  //     }}
  //     className={
  //       data?.deviceOwnerStatus === "on_pending" ||
  //       data?.deviceOwnerStatus === "off_pending"
  //         ? "switchButtonPending"
  //         : ""
  //     }
  //     checked={
  //       !(data?.deviceOwnerStatus === "off" ||
  //       data?.deviceOwnerStatus === "on_pending")
  //     }
  //     unCheckedChildren={data?.deviceOwnerStatus === "off" ? "No" : "No"}
  //     checkedChildren={data?.deviceOwnerStatus === "on" ? "Yes" : "Yes"}
  //     // checkedChildren={data?.deviceOwnerStatus === "off" ? "No" : "No"}
  //     // unCheckedChildren={data?.deviceOwnerStatus === "on" ? "Yes" : "Yes"}
  //   />
  // </Popconfirm>
);

const StatusCell = (data, onChange) => (
  <Switch
    onChange={(val, e) => {
      stopPropagation(e);
      onChange(data, val);
    }}
    checked={data.isActive == true}
    checkedChildren="Active"
    unCheckedChildren="Disabled"
    // disabled
  />
);
const AiTutorEnabled = (data, onChange) => (
  <Switch
    onChange={(val, e) => {
      stopPropagation(e);
      onChange(data, val);
    }}
    checked={data.isAiTutorEnabled == true}
    checkedChildren="Enabled"
    unCheckedChildren="Disabled"
    // disabled
  />
);
const ImageCell = (src) => <ImageCellView src={src} />;

const LinkCell = (link, text) => <Link to={`${link}`}>{text}</Link>;

const TextCell = (text) => <div>{text}</div>;

const showTickCell = (status, attended = true, field = "name") =>
attended ? 
  status ? (
    <Icon
      type="check-circle-o"
      theme="outlined"
      style={checkTrueIConStyle}
      title={field === "status"? "Passed" : "Yes"}
    />
  ) : (
    <Icon
      type="close-circle-o"
      theme="outlined"
      style={checkFalseIConStyle}
      // title="No"
      title={field === "status"? "Failed" : "No"}
    />
  ) : (
    <Icon
      type="minus-circle-o"
      theme="outlined"
      style={checkFalseIConStyleNotAtt}
      title="Not attempted"
    />
  );

const AssignCell = (object, assignUser) => (
  <a
    hrefo={`#`}
    onClick={(e) => {
      e.stopPropagation();
      assignUser(object);
    }}
  >
    Assign User
  </a>
);

const UnAssignCell = (object, unAssign) => (
  <div>
    <span>
      {object.user
        ? `${object.user.firstName} ${object.user.lastName || ""}`
        : ""}
    </span>
    <Popconfirm
      title="Are you sure to unassign this user?"
      onConfirm={(e) => {
        e.stopPropagation();
        confirm(unAssign, object);
      }}
      onCancel={cancel}
      okText="Yes"
      cancelText="No"
    >
      <Icon
        type="minus-circle"
        theme="filled"
        style={removeIconStyle}
        title="Unassign user"
        onClick={(e) => {
          stopPropagation(e);
        }}
      />
    </Popconfirm>
  </div>
);
const QRCell = (data, onClickQR) => (
  <Icon
    style={{ fontSize: "17px" }}
    type="qrcode"
    title="Generate QR Code"
    onClick={(e) => {
      e.stopPropagation();
      onClickQR(data);
    }}
  />
);

const AnalyticsCell = (data, onAnalyticsClick) => (
  <Icon
    style={{ fontSize: "17px" }}
    type="bar-chart"
    title="Data Sheet"
    onClick={(e) => {
      e.stopPropagation();
      onAnalyticsClick( data);
    }}
  />
);

const ActionCell = (object, userLink, userEditLink, remove) => (
  <Fragment>
    <Link to={`${userLink}`}>
      <Icon type="eye" theme="outlined" style={viewIconStyle} title="View" />
    </Link>
    <Link to={`${userEditLink}`}>
      <Icon
        type="edit"
        theme="outlined"
        onClick={(e) => e.stopPropagation()}
        style={iconStyle}
        title="Edit"
      />
    </Link>
    <Popconfirm
      title="Are you sure to delete"
      onConfirm={(e) => {
        e.stopPropagation();
        confirm(remove, object);
      }}
      onCancel={cancel}
      okText="Yes"
      cancelText="No"
    >
      <Icon
        type="delete"
        theme="outlined"
        style={delIconStyle}
        title="Delete"
        onClick={(e) => e.stopPropagation()}
      />
    </Popconfirm>
  </Fragment>
);

const ActionCellTwo = (object, userLink, remove) => (
  <Fragment>
    <Link to={`${userLink}`}>
      <Icon type="eye" theme="outlined" style={viewIconStyle} title="View" />
    </Link>
    <Popconfirm
      title="Are you sure to delete?"
      onConfirm={(e) => {
        e.stopPropagation();
        confirm(remove, object);
      }}
      onCancel={cancel}
      okText="Yes"
      cancelText="No"
    >
      <Icon type="delete" theme="filled" style={delIconStyle} title="Delete" />
    </Popconfirm>
  </Fragment>
);

const ActionViewCell = (object, userLink) => (
  <Link to={`${userLink}`}>
    <Icon type="eye" theme="outlined" style={viewIconStyle} title="View" />
  </Link>
);

const ClickCell = (
  object,
  edit,
  remove,
  link = undefined,
  endCall = undefined,
  exportExcel = undefined,
  exportExcelMeeting = undefined,
  recording = undefined,
  startMeeting = undefined,
  joinMeeting = undefined,
  copy = false,
  title = undefined,
  id = undefined,
  copyUrl = false
) => (
  <Fragment>
    {copyUrl &&
      (object.status === "Active" && (
        <CopyToClipboard
          text={`${object.guestMeetingLink}`}
          onCopy={() => Notification("success", "Copied To Clipboard")}
        >
          <Icon
            type="copy"
            theme="outlined"
            style={viewIconStyle}
            title="Copy to Clipboard"
          />
        </CopyToClipboard>
      ))}
    {startMeeting &&
      new Date() >= new Date(object.startTime) &&
      new Date(object.startTime) <=
        new Date().setHours(new Date().getHours() + 1) &&
      object.isMeetingStartPermission &&
      object.status === "Scheduled" && (
        <Icon
          type="play-circle"
          style={viewIconStyle}
          onClick={() => startMeeting(object._id)}
          title="Start meeting"
        />
      )}
    {joinMeeting &&
      object.status === "Active" && (
        <Icon
          type="plus-circle"
          style={viewIconStyle}
          onClick={() => joinMeeting(object._id)}
          title="Join Meeting"
        />
      )}
    {object.isPublished &&
    object.isPublished === true &&
    (object.type && object.type === "lesson") ? (
      <div />
    ) : (
      edit && (
        <Icon
          type="edit"
          theme="outlined"
          onClick={(e) => {
            edit(object);
            e.stopPropagation();
          }}
          style={iconStyle}
          title="Edit"
        />
      )
    )}
    {remove && (
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ display: "inline-table" }}
      >
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => confirm(remove, object)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Icon
            type="delete"
            theme="outlined"
            style={delIconStyle}
            title="Delete"
          />
        </Popconfirm>
      </div>
    )}
    {copy && <Copy copy={copy} object={object} />}
    {link && (
      <Link to={`${link}`}>
        <Icon type="eye" theme="outlined" style={viewIconStyle} title="View" />
      </Link>
    )}
    {exportExcel && (
      <Icon
        type="export"
        theme="outlined"
        onClick={() => exportExcel(object)}
        style={{
          fontSize: 17,
          marginRight: 10,
          cursor: "pointer",
          color: "#1d73b3",
        }}
        title="Export"
      />
    )}
    {endCall &&
      object.status === "Active" && (
        <Icon
          type="poweroff"
          theme="outlined"
          onClick={() => endCall(object._id)}
          style={{
            fontSize: 17,
            marginRight: 10,
            cursor: "pointer",
            color: "red",
          }}
          title="End Call"
        />
      )}
    {exportExcelMeeting && (
      <Icon
        type="eye"
        theme="outlined"
        style={viewIconStyle}
        onClick={() => exportExcelMeeting(object._id)}
        title="Export Meeting Info"
      />
    )}
    {recording && (
      <Icon
        type="video-camera"
        theme="outlined"
        style={viewIconStyle}
        onClick={() => recording(object._id)}
        title="Recording Details"
      />
    )}
  </Fragment>
);

const imageCell = (link) => <img style={imageStyle} src={link} alt="" />;
const ratingCell = (val) => <Rate disabled value={val} />;
const notificationCell = (object, notify, categorize, chat, addContent,openImage,openApply,openOrder  ,onAnalyticsClick) => (
  <div style={{display: "flex", gap: "8px"}}>
     {object?.forAdmin ? (
      <Icon
        style={{ fontSize: "20px", color: "#d9d9d9", cursor: "not-allowed" }} // Disabled icon
        type="bar-chart"
        title="Data Sheet (Disabled)"
      />
    ) : (
      <Icon
        style={{ fontSize: "20px", color: "#1890ff", cursor: "pointer" }} // Active icon
        type="bar-chart"
        title="Data Sheet"
        onClick={(e) => {
          e.stopPropagation();
          onAnalyticsClick(object);
        }}
      />
    )}

    {/* <Badge style={{ minWidth: '10px', height: "18px" }} count={object?.resources?.length ? 1 : 0}> */}
    <Icon
      type="file-image"
      theme="filled"
      onClick={() => object?.resources?.length && openImage(object)}
      style={object?.resources?.length ? iconStyle : {...iconStyle, color: "#d9d9d9", cursor: "not-allowed"}}
      title="Images"
      disabled={!object?.resources?.length}
    />
    {/* </Badge> */}
    {/* <Icon
      type="diff"
      theme="filled"
      onClick={() => openApply(object)}
      style={iconStyle}
      title="Apply-Reward"
    /> */}
    <img title="Apply-Reward" onClick={() => openApply(object)} src={Drop} height={"18px"} style={{marginLeft: "-2px"}} />
    {/* <Icon
      type="check-circle"
      theme="filled"
      onClick={() => openOrder(object)}
      style={iconStyle}
      title="Apply-Order"
    /> */}
    <Icon
      type="notification"
      theme="filled"
      onClick={() => notify(object)}
      style={iconStyle}
      title="Notify"
    />
    <Icon
      type="database"
      theme="filled"
      style={iconStyle}
      onClick={() => categorize(object)}
      title="Categorize"
    />
    <Icon
      type="file-add"
      style={iconStyle}
      title="Share Content"
      onClick={() => addContent(object)}
    />
    <Badge style={{ minWidth: '10px', height: "18px" }} count={object?.room?.unreadMessages ?? 0}>
    <Icon
      type="message"
      style={iconStyle}
      title="Message"
      onClick={() => chat(object)}
    />
    </Badge>
  </div>
);

const multipleImageCell = (links) => {
  if (links && links.length) {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {links?.map((link) => (
          <Tooltip key={link?.name} title={toTitleCase(link?.name || "")}>
            {link?.value && link?.value != "" ? (
              <img
                style={{
                  width: 50,
                  height: 50,
                  margin: 5,
                  border: "1px solid grey",
                  borderRadius: "5px",
                }}
                src={`${config.baseUrl}/${link.value}`}
                alt=""
              />
            ) : (
              <div
                style={{
                  width: 50,
                  height: 50,
                  margin: 5,
                  border: "1px solid grey",
                  borderRadius: "5px",
                }}
              />
            )}
          </Tooltip>
        ))}
      </div>
    );
  }
  return null;
};

const ModalActions = ({
  onEdit,
  onDelete,
  deleteMessage = undefined,
  id = null,
  copy,
  object,
  link,
  onSave,
  saveMessage = undefined,
  isSaveShow,
  status,
  createdFrom,
  onlaunch,
  onPublish = undefined,
  isLaunched,
  publishMessage,
  hideEditDelete,
  isCoursePublished,
  deleteLesson,
  isLaunchToClassroom,
  launchToClassroomMessage,
  onlaunchToClass
}) => (
  <Fragment>

    {isLaunchToClassroom &&
       ( 
        <div style={{position: "absolute", bottom: "0"}}>
            <Popconfirm
              title={launchToClassroomMessage || "Are you sure to publish this lesson?"}
              onConfirm={(e) => {
                e.stopPropagation();
                onlaunchToClass(id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                shape="circle"
                icon={isLaunched ? "check" : "rocket"}
                disabled={isLaunched}
                style={editButtonStyle}
                onClick={(e) => e.stopPropagation()}
                title={isLaunched ? "Launched" : "Launch"}
              />
            </Popconfirm>
        </div>
      )}
    {createdFrom &&
      publishMessage &&
      publishMessage !== "" &&
      isSaveShow &&
      createdFrom !== "course" &&
       (
        
        <div style={{position: "absolute", top: "0"}}>
          {createdFrom === "classroom" && !isLaunched ? (
            <Popconfirm
              title={publishMessage || "Are you sure to publish this lesson?"}
              onConfirm={(e) => {
                e.stopPropagation();
                onlaunch(id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                shape="circle"
                icon={isLaunched ? "check" : "rocket"}
                disabled={isLaunched}
                style={editButtonStyle}
                title={isLaunched ? "Launched" : "Launchaa"}
              />
            </Popconfirm>
          ) : (
            <Button
              shape="circle"
              icon={isLaunched ? "check" : "rocket"}
              disabled={isLaunched}
              style={editButtonStyle}
              title={isLaunched ? "Launched" : "Launch"}
            />
          )}
        </div>
      )}

    {onPublish ? (
      <>
        {isCoursePublished ? (
          <Button  style={publishedButtonStyle} title="Published" disabled={true}>Published
            {/* <Icon type="check" theme="outlined" title="Published" /> */}
          </Button>
        ) : (
          <Popconfirm
            title="Are you sure to publish this course?"
            onConfirm={(e) => {
              e.stopPropagation();
              onPublish(id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button  style={publishButtonStyle} title="Publish">Publish
              {/* <Icon type="arrow-up" theme="outlined" title="Publish" /> */}
            </Button>
          </Popconfirm>
        )}
      </>
    ) : (
      <></>
    )}
    {onEdit &&
      !hideEditDelete && (
        <Button
          shape="circle"
          icon="edit"
          onClick={onEdit}
          style={editButtonStyle}
          title="Edit"
        />
      )}
    {onSave && (
      <Popconfirm
        title={saveMessage || "Are you sure to save?"}
        onConfirm={(e) => {
          e.stopPropagation();
          onSave(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        {!isSaveShow && (
          <Button
            shape="circle"
            icon="save"
            style={editButtonStyle}
            title="Save"
          />
        )}
      </Popconfirm>
    )}
    {console.log(!deleteLesson)}
    {onDelete &&
      !deleteLesson && (
        <Popconfirm
          title={deleteMessage || "Are you sure to delete?"}
          onConfirm={(e) => {
            e.stopPropagation();
            onDelete(id);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            shape="circle"
            icon="delete"
            style={delButtonStyle}
            title="Delete"
          />
        </Popconfirm>
      )}
    {copy && <Copy copy={copy} object={object} />}
    {link && (
      <Link to={`${link}`}>
        <Button
          shape="circle"
          icon="eye"
          theme="outlined"
          style={viewIconStyle}
          title="View"
        />
      </Link>
    )}
  </Fragment>
);

const BigBlueActions = ({
  object,
  remove,
  link = undefined,
  endCall = undefined,
  exportExcel = undefined,
  exportExcelMeeting = undefined,
  recording = undefined,
  startMeeting = undefined,
  joinMeeting = undefined,
  copy = false,
  copyUrl = false,
  deleteMessage = undefined,
  downLoadVideo,
  videoLink,
}) => (
  <Fragment>
    {copyUrl &&
      (object.status !== "Expired" &&
        object?.guestMeetingLink && (
          <CopyToClipboard
            text={`${object.guestMeetingLink}`}
            onCopy={() => Notification("success", "Copied To Clipboard")}
          >
            <Button
              shape="circle"
              icon="copy"
              theme="outlined"
              style={viewIconStyle}
              title="Copy to Clipboard"
            />
          </CopyToClipboard>
        ))}
    {startMeeting &&
      new Date() >= new Date(object.startTime) &&
      new Date(object.startTime) <=
        new Date().setHours(new Date().getHours() + 1) &&
      object.status === "Scheduled" && (
        <Button
          shape="circle"
          icon="play-circle"
          style={viewIconStyle}
          onClick={() => startMeeting(object._id)}
          title="Start meeting"
        />
      )}
    {joinMeeting &&
      object.status === "Active" && (
        <Button
          shape="circle"
          icon="plus-circle"
          style={viewIconStyle}
          onClick={() => joinMeeting(object._id)}
          title="Join Meeting"
        />
      )}
    {remove && (
      <div
        onClick={(e) => e.stopPropagation()}
        style={{ display: "inline-table" }}
      >
        <Popconfirm
          title={deleteMessage || "Are you sure to delete?"}
          onConfirm={() => confirm(remove, object)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button
            shape="circle"
            icon="delete"
            theme="outlined"
            style={delIconStyle}
            title="Delete"
          />
        </Popconfirm>
      </div>
    )}
    {copy && <Copy copy={copy} object={object} />}
    {link && (
      <Link to={`${link}`}>
        <Button
          shape="circle"
          icon="eye"
          theme="outlined"
          style={viewIconStyle}
          title="View"
        />
      </Link>
    )}
    {exportExcel && (
      <Button
        shape="circle"
        icon="export"
        theme="outlined"
        onClick={() => exportExcel(object)}
        style={{
          fontSize: 17,
          marginRight: 10,
          cursor: "pointer",
          color: "#1d73b3",
        }}
        title="Export"
      />
    )}
    {endCall &&
      object.status === "Active" && (
        <Button
          shape="circle"
          icon="poweroff"
          theme="outlined"
          onClick={() => endCall(object._id)}
          style={{
            fontSize: 17,
            marginRight: 10,
            cursor: "pointer",
            color: "red",
          }}
          title="End Call"
        />
      )}
    {exportExcelMeeting && (
      <Button
        shape="circle"
        icon="eye"
        theme="outlined"
        style={viewIconStyle}
        onClick={() => exportExcelMeeting(object._id)}
        title="Export Meeting Info"
      />
    )}
    {object?.publishRecordingOnGoing ? (
      <p title="Syncing  Recordings">
        <Button shape="circle" theme="outlined" loading={true} />
      </p>
    ) : object?.recordLink ? (
      <Button
        shape="circle"
        icon="video-camera"
        theme="outlined"
        style={viewIconStyle}
        title="Open Recording"
        onClick={() => {
          window
            .open(`${serverBaseUrl()}/${object?.recordLink}`, "_blank")
            .focus();
          // downLoadVideo(object?.recordLink);
        }}
      />
    ) : (
      recording && (
        <Button
          shape="circle"
          icon="cloud-upload"
          theme="outlined"
          style={viewIconStyle}
          onClick={() => recording(object._id)}
          title="Sync Recording Details"
        />
      )
    )}
    {videoLink?.playback?.format?.url && (
      <Button
        shape="circle"
        icon="link"
        theme="outlined"
        style={viewIconStyle}
        onClick={() => {
          window.open(videoLink?.playback?.format?.url, "_blank").focus();
        }}
        title="Open Recording in BBB"
      />
    )}
  </Fragment>
);

export {
  ActionCell,
  ActionCellTwo,
  ActionViewCell,
  AssignCell,
  ButtonCell,
  ClickCell,
  DateCell,
  DeleteCell,
  EditableCell,
  FilterDropdown,
  ImageCell,
  LinkCell,
  PinCell,
  CSDKStatusCell,
  StatusCell,
  AiTutorEnabled,
  SwitchCell,
  multiSwitchCell,
  multiSwitchCellReplace,
  multiSwitchCellReplaceAss,
  mulitWifiSwitch,
  multiCSDKSwitch,
  multiPinSwitch,
  multiDeviceOwnerSwitch,
  customSwitchCell,
  ApproveCell,
  isPublicCell,
  isExploreCell,
  TextCell,
  UnAssignCell,
  WifiCell,
  showTickCell,
  imageCell,
  multipleImageCell,
  HealthCell,
  notificationCell,
  ratingCell,
  ModalActions,
  BigBlueActions,
  QRCell,
  AnalyticsCell
};
