import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import LayoutContentWrapper from "../../components/utility/layoutWrapper";
import LayoutContent from "../../components/utility/layoutContent";
import SortTable from "../../components/tables/antTables/tableViews/CustomExapandedTable";
import { Modal, Button, Spin as Spins, Select } from "antd";
import html2pdf from "html2pdf.js";
import API from "../../helpers/API";
import "./export.style.css";
import { createColumns } from "./closed-tickets";
import ReportHeader from "./reportHeader";
import graphConfigurations from "./graphConfig";
import Spin from "../../components/Spin/spin.style";

const { Option } = Select;

const PdfReport = () => {
  const [graphs, setGraphs] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [schools, setSchools] = useState([]);
  const [students, setStudents] = useState([]);
  const [studentDetails, setStudentDetails] = useState({});
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const graphsPerPage = 6;

  const analyticsTitle = [
    "ENGAGEMENT & DIGITAL HEALTH",
    "MASTERY PROGRESS",
    "CONTENT FOCUS & REWARDS",
    "REWARDS & SUPPORT",
  ];

  const columns = createColumns();

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      const response = await API.common.listOrganization();
      setOrganizations(response.data?.organizations || []);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  const fetchStudentDetails = async (student) => {
    console.log("fetch Student details", student);
    try {
      const response = await API.users.details(student.id);
      setStudentDetails(response?.data?.student || {});
    } catch (error) {
      console.error("Error fetching student details:", error);
    }
  };

  const fetchClosedTicket = async () => {
    try {
      const response = await API.analytics.list({
        studentId: selectedStudent,
        graphId: 24,
      });
      setTableData(response?.data?.result);
    } catch (error) {
      console.error("Error fetching support tickets:", error);
    }
  };

  const fetchSchools = async (organizationId) => {
    try {
      const response = await API.common.organizationSchools(organizationId);
      setSchools(response.data.schools || []);
    } catch (error) {
      console.error("Error fetching schools:", error);
    }
  };

  const fetchStudents = async (schoolId) => {
    try {
      const response = await API.common.students(schoolId);
      setStudents(response.data.students || []);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const formatDateToDayMonth = (input) => {
    if (typeof input !== "string" || !/^\d{4}-\d{2}-\d{2}$/.test(input))
      return input;

    const date = new Date(input);
    return date.toLocaleDateString("en-US", { day: "numeric", month: "short" });
  };

  const fetchGraphData = async () => {
    setLoading(true);

    try {
      const dynamicGraphs = graphConfigurations.filter(
        (graph) => !graph.isStatic
      );
      const apiCalls = dynamicGraphs.map((graph) =>
        API.analytics.list({ studentId: selectedStudent, graphId: graph.id })
      );

      const responses = await Promise.allSettled(apiCalls);

      const updatedDynamicGraphs = dynamicGraphs.map((graph, index) => {
        const result =
          responses[index]?.status === "fulfilled"
            ? responses[index].value?.data?.result
            : null;

        if (graph.id === 22 && result) {
          const totalRatings = result.totalRatings || 0;
          graph.options.title.text = `Aunty A Rating ( Total Rating: ${totalRatings} )`;
        }
        if (graph.id === 23 && result) {
          const totalCount = result.totalCount || 0;
          graph.options.title.text = `Mood Summary ( Total Days: ${totalCount} )`;
        }

        return {
          ...graph,
          series: result?.series || [],
          options: {
            ...graph.options,
            labels: result?.labels || [],
            xaxis: {
              categories: result?.categories?.map(formatDateToDayMonth) || [],
            },
          },
        };
      });

      const updatedGraphs = [
        ...updatedDynamicGraphs,
        ...graphConfigurations.filter((graph) => graph.isStatic),
      ];
      await fetchClosedTicket();

      setGraphs(updatedGraphs);
    } catch (error) {
      console.error("Error fetching graph data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateReport = async () => {
    try {
      setIsModalVisible(true);
      if (!graphs?.length) {
        await fetchGraphData();
      }
    } catch (error) {
      console.error("Error generating report:", error);
    }
  };

  const preloadImages = async (imageUrls) => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = src;

        img.onload = () => resolve(img);
        img.onerror = (e) => reject(new Error(`Failed to load image: ${src}`));
      });
    };

    const imagePromises = imageUrls.map(loadImage);
    await Promise.all(imagePromises);
  };

  const renderGraphs = () => {
    // if (loading) return <Spin spinning={loading}>
    //     <p style={{ display:"flex" , alignItems:"center" , justifyContent : 'center' , textAlign: "center" }}>{loading ? "Loading..." : "No Graphs found."}</p>
    // </Spin>;

    return graphs.map((graph) => (
      <div key={graph.id} className="graph-item">
        <ReactApexChart
          options={graph.options}
          series={graph.series}
          type={graph.options.chart.type}
          height={250}
        />
      </div>
    ));
  };
  const generatePdf = async () => {
    try {
      const imageUrls = [];
      if (studentDetails.avatar?.urlLowquality) {
        imageUrls.push(studentDetails.avatar.urlLowquality);
      } else {
        imageUrls.push(
          JSON.parse(localStorage.getItem("DefaultLogos"))?.find(
            (item) => item.title === "admin"
          )?.logo
        );
      }

      await preloadImages(imageUrls);
      // Wait for the UI to update before starting the export process
      await new Promise((resolve) => setTimeout(resolve));

      const currentDate = new Date();
      const dateString = currentDate.toLocaleDateString();
      const timeString = currentDate.toLocaleTimeString().replace(/:/g, '-');

      const element = document.getElementById("modal-content");
      const opt = {
        filename: `${studentDetails.firstName} ${studentDetails.lastName}-Data Sheet-Report-${dateString}-${timeString}.pdf`,
        html2canvas: { scale: 2, useCORS: true, allowTaint: false },
        jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
      };
      await html2pdf()
        .from(element)
        .set(opt)
        .save();
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsExporting(false);
    }
  };

  const renderPages = () => {
    if (loading) return <Spin spinning={loading} />;

    const pages = [];

    for (let i = 0; i < graphs.length; i += graphsPerPage) {
      const pageGraphs = graphs.slice(i, i + graphsPerPage);
      const currentTitle = analyticsTitle[pages.length];
      pages.push(
        <div className="page" key={`page-${i / graphsPerPage}`}>
          <ReportHeader
            studentDetails={studentDetails}
            analyticsTitle={currentTitle}
          />
          <div className="modal-graph-container">
            {pageGraphs.map((graph) => (
              <div key={graph.id} className="graph-item">
                <ReactApexChart
                  options={graph.options}
                  series={graph.series}
                  type={graph.options.chart.type}
                  height={250}
                />
              </div>
            ))}
          </div>
          <div className="footer">
            <p>The Future Of Education. Now.</p>
          </div>
        </div>
      );
    }
    pages.push(
      <div className="page" key={`last-page`}>
        <ReportHeader
          studentDetails={studentDetails}
          analyticsTitle={"REWARDS & SUPPORT"}
        />

        <h3
          style={{
            margin: "10px",
            textAlign: "center",
            marginTop: "20px",
            //  fontWeight: "bold",
          }}
        >
          Last Few Tickets
        </h3>
        <SortTable
          loading={loading}
          columns={columns}
          locale={{ emptyText: "No data found" }}
          dataSource={tableData ?? []}
          pagination={false}
          style={{
            width: "100%",
          }}
          disabledPointer={true}
        />
      </div>
    );
    return pages;
  };

  return (
    <LayoutContentWrapper>
      <LayoutContent>
        <h2>Data Sheet</h2>
        <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
          <Select
            placeholder="Select Organization"
            style={{ width: 200 }}
            onChange={fetchSchools}
            loading={!organizations.length}
            showSearch
            optionFilterProp="children"
          >
            {organizations.map((org) => (
              <Option key={org.id} value={org.id}>
                {org.name}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Select School"
            style={{ width: 200 }}
            onChange={fetchStudents}
            showSearch
            optionFilterProp="children"
          >
            {schools.map((school) => (
              <Option key={school.id} value={school.id}>
                {school.name}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="Select Student"
            style={{ width: 200 }}
            onChange={(studentId) => {
              setSelectedStudent(studentId);
              const selectedStudentObj = students.find(
                (student) => student.id === studentId
              );
              fetchStudentDetails(selectedStudentObj);
            }}
            showSearch
            optionFilterProp="children"
          >
            {students.map((student) => (
              <Option key={student.id} value={student.id}>
                {`${student.firstName} ${student.lastName}`}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={fetchGraphData}
            disabled={!selectedStudent || loading}
          >
            Load Graphs
          </Button>
          <Button
            type="primary"
            onClick={handleGenerateReport}
            disabled={!selectedStudent || loading}
          >
            Generate Report
          </Button>
        </div>
        {loading ? (
          <Spin
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
            spinning={loading}
          />
        ) : (
          <div id="main-listing" className="graph-container">
            {renderGraphs()}
          </div>
        )}
        <Modal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          width={800}
          // loading={loading}
          bodyStyle={{ maxHeight: "calc(95vh - 200px)", overflow: "auto" }}
          title="Export Preview"
          footer={[
            <Button key="close" onClick={() => setIsModalVisible(false)}>
              Close
            </Button>,
            // <Spins spinning={true} style={{marginRight:"10px"}} key="spinner" />,

            isExporting ? (
              <Spins
                spinning={true}
                style={{ marginRight: "20px", marginLeft: "20px" }}
                key="spinner"
              />
            ) : (
              <Button
                key="export"
                type="primary"
                onClick={() => {
                  setIsExporting(true); // Update state immediately on button click
                  generatePdf();
                }}
                disabled={isExporting}
                loading={isExporting}
              >
                Export to PDF
              </Button>
            ),
          ]}
        >
          {loading ? (
            <Spin
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
              spinning={loading}
            />
          ) : (
            <div id="modal-content">{renderPages()}</div>
          )}
        </Modal>
      </LayoutContent>
    </LayoutContentWrapper>
  );
};

export default PdfReport;
